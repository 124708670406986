@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
@font-face {
    font-family: GilroyHome;
    src: url(/static/media/Gilroy-Medium.32817d86.woff2);
  }
  
  @font-face {
    font-family: GilroyHomeBold;
    src: url(/static/media/Gilroy-Bold.5dacc44c.woff2);
  }

.imgClose{
    width: 50px;
}

.banner-links{
    font-family: GilroyHome;
    background-image: url(/static/media/banner-links-bg.8ee66188.png);
    background-size: 100% 100%;
    padding-bottom: 100px;
    width: 751px;
    margin: 0px auto;
    position: relative;
    top: 219px;
    left: -12px;
    height: 212px;
}
.link-buttons a{
    background: #C89C34;
    box-shadow: 0px 4px 4px rgba(200, 156, 52, 0.2);
    border-radius: 20px;
    padding: 10px 20px;
    color: #fff;
}
.link-row {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 2em;
    gap: 2em;
}


@media screen and (max-width: 430px) {
    .link-row {
        grid-gap: 0;
        gap: 0;
        grid-template-columns: 170px 200px auto 100px;
    }
    
}

@media screen and (max-width: 390px) {
    .link-row {
        grid-template-columns: 80px 200px auto 100px;
    }
}

.lb1 {
    font-family: GilroyHome;
    position: relative;
    left: -5px;
    top: 83px;
}
.lb2 {
    font-family: GilroyHome;
    position: relative;
    top: 82px;
    right: -250px;
}

.lb3 {
    font-family: GilroyHome;
    position: relative;
    top: 184px;
    left: -78px;
}
.lb4 {
    font-family: GilroyHome;
    position: relative;
    top: 185px;
    right: -357px;
    max-width: 100px;
}
.lb1 a, .lb2 a, .lb3 a, .lb4 a{
    font-family: GilroyHome;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .banner-links{
        width: 100%;
        top: 220px;
        height: 191px;
    }
    .lb1{
        left: 40px;
        top: 75px;
    }
    .lb2 {
        position: relative;
        top: 75px;
        right: -270px;
        max-width: 88px;
    }
    .lb3 {
        position: relative;
        top: 164px;
        left: 23px;
    }
    .lb4 {
        position: relative;
        top: 164px;
        right: -280px;
        max-width: 88px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .banner-links{
        width: 100%;
        top: 220px;
        height: 191px;
    }
    .lb1{
        left: 40px;
        top: 75px;
    }
    .lb2 {
        position: relative;
        top: 75px;
        right: -270px;
        max-width: 88px;
    }
    .lb3 {
        position: relative;
        top: 164px;
        left: 23px;
    }
    .lb4 {
        position: relative;
        top: 164px;
        right: -280px;
        max-width: 88px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .banner-links{
        width: 100%;
        top: 330px;
        height: 149px;
    }
    .lb1{
        left: 40px;
        top: 56px;
    }
    .lb2 {
        position: relative;
        top: 56px;
        right: -120px;
        max-width: 88px;
    }
    .lb3 {
        position: relative;
        top: 124px;
        left: 23px;
    }
    .lb4 {
        position: relative;
        top: 122px;
        right: -150px;
        max-width: 88px;
    }
}

@media (min-width: 381px) and (max-width: 480px) {
    .banner-links{
        width: 100%;
        top: 230px;
        height: 149px;
    }
    .lb1{
        left: 40px;
        top: 56px;
    }
    .lb2 {
        position: relative;
        top: 56px;
        right: -93px;
        max-width: 88px;
    }
    .lb3 {
        position: relative;
        top: 124px;
        left: 23px;
    }
    .lb4 {
        position: relative;
        top: 122px;
        right: -102px;
        max-width: 88px;
    }
}
@media (min-width: 320px) and (max-width: 380px) {
    .banner-links{
        position: relative;
        width: 320px;
        top: 230px;
        height: 149px;
    }
    .link-buttons{
        position: absolute;
    }
    .lb1{
        left: 10px;
        top: 56px;
    }
    .lb2 {
        position: relative;
        top: 56px;
        right: -123px;
        max-width: 88px;
    }
    .lb3 {
        position: relative;
        top: 124px;
        left: 0px;
    }
    .lb4 {
        position: relative;
        top: 122px;
        right: -150px;
        max-width: 88px;
    }
}
.wrapper{
    width: 100%;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
}
.wrapper .title{
    font-family: GilroyHome;
    position: relative;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #464754;
    margin-bottom: 30px;
}
.wrapper form .user-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 12px 0;
    color: #807373;
}
form .user-details .input-box{
    margin-bottom: 15px;
    width: calc(100% / 2 - 20px);
}
form .user-details .input-box-full{
    margin-bottom: 15px;
    width: calc(100%);
}
.user-details .input-box input, .user-details .input-box-full input{
    height: 45px;
    width: 100%;
}
.user-details .input-box .details, .user-details .input-box-full .details{
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
}
.user-details .input-box input{
    font-family: GilroyHome;
    height: 45px;
    width: 100%;
    outline: none;
    padding-left: 15px;
    font-size: 16px;
    transition: all .3s ease;
    border: 1.5px solid rgba(166, 159, 159, 0.4);
    border-radius: 8px;
    margin-bottom: 15px;
}
.user-details .input-box-full textarea{
    width: 100%;
    min-height: 60px;
    border: 1.5px solid rgba(166, 159, 159, 0.4);
    border-radius: 8px;
}
.user-details .input-box-full textarea:hover, .user-details .input-box-full textarea:focus, .user-details .input-box-full textarea:focus-visible{
    border: 1.5px solid rgba(166, 159, 159, 0.4);
    outline: none;
}
.user-details .input-box-full input{
    font-family: GilroyHome;
    height: 45px;
    width: 100%;
    outline: none;
    padding-left: 15px;
    font-size: 16px;
    transition: all .3s ease;
    border: 1.5px solid rgba(166, 159, 159, 0.4);
    border-radius: 8px;
    margin-bottom: 15px;
}
form .gender-details .gender-title{
    font-size: 20px;
    font-weight: 500;
}
.gender-details .category{
    display: flex;
    width: 80%;
    margin: 14px 0;
    justify-content: space-between;
}
.gender-details .category label{
    font-family: GilroyHome;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #807373;
    padding-bottom: 15px;
}
form .button{
    margin-top: 40px;
}
form .button input{
    font-family: GilroyHome;
    width: 250px;
    margin: 0px auto;
    color: #fff;
    outline: none;
    cursor: pointer;
    display: block;
    padding: 10px;
    background: #00783F;
    border: 3px solid rgba(166, 159, 159, 0.2);
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
}
.mt40{
    margin-top: 40px;
}
.form-error{
    color: #e70000;;
}
.submitDisable{
    background-color: #aaa;
}
.submitActive{
    background-color: #00783F;
}

.alert-error{
    background-color: #c002022e;
    color: #c00202;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
}
.alert-success{
    background-color: #00784037;
    color: #00783F;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
}

/* Media Query */
@media (min-width: 768px) and (max-width: 1024px) {
    .wrapper{
        max-width: 100%;
    }
    form .user-details .input-box{
        margin-bottom: 15px;
        width: 100%;
    }
    .gender-details .category{
        width: 100%;
    }
    .user-details::-webkit-scrollbar{
        width: 0;
    }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .wrapper{
        max-width: 100%;
    }
    form .user-details .input-box{
        margin-bottom: 15px;
        width: 100%;
    }
    .gender-details .category{
        width: 100%;
    }
    .user-details::-webkit-scrollbar{
        width: 0;
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    .wrapper{
        max-width: 100%;
    }
    form .user-details .input-box{
        margin-bottom: 15px;
        width: 100%;
    }
    .gender-details .category{
        width: 100%;
    }
    .user-details::-webkit-scrollbar{
        width: 0;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .wrapper{
        max-width: 100%;
    }
    form .user-details .input-box{
        margin-bottom: 15px;
        width: 100%;
    }
    .gender-details .category{
        width: 100%;
    }
    .user-details::-webkit-scrollbar{
        width: 0;
    }
}

.wrapper{
    width: 100%;
    background: transparent;
    padding: 10px;
    border-radius: 5px;
}
.wrapper .title{
    font-family: GilroyHome;
    position: relative;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #464754;
    margin-bottom: 30px;
}
.wrapper form .user-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 12px 0;
    color: #807373;
}
.wrapper-content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
}
.card{
    font-family: GilroyHome;
    background: #FFFFFF;
    border-radius: 10px;
    min-height: 350px;
}
.card-header-btn{
    width: 50%;
    float: left;
}
.card-header-btn button{
    font-family: GilroyHome;
    background: #00783F;
    border-radius: 4px;
    color: #fff;
    border: none;
    padding: 7px;
}
.card-header-status{
    font-family: GilroyHome;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 120%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #464754;
}
.card-body{
    padding: 20px;
}
.progress-txt{
    font-family: GilroyHome;
    width: 50%;
    float: left;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;letter-spacing: 0.02em;
    color: #807373;
}
.card2 .card-header-status, .card-header-status{
    font-family: GilroyHome;
    color: #807373;
}
.progress-count{
    font-family: GilroyHome;
    text-align: right;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #00783F;
}
.card-list{
    margin-top: 30px;
}
.card-list .list-item{
    padding: 10px 0px;
}
.card-list .list-item label{
    font-family: GilroyHome;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #A69F9F;

}
.card-list .list-item label.checked{
    color: #464754;
}

.card1 .card .card-header{
    background: rgba(0, 120, 63, 0.08);
    border-bottom: 0.5px solid #D7D9DD;
    padding: 20px 20px 30px 20px;
    border-radius: 10px 10px 0px 0px;
}
.card2 .card .card-header{
    background: rgba(166, 159, 159, 0.14);
    border-bottom: 0.5px solid #D7D9DD;
    padding: 20px 20px 30px 20px;
    border-radius: 10px 10px 0px 0px;
}
.card3 .card .card-header{
    background: rgba(166, 159, 159, 0.14);
    border-bottom: 0.5px solid #D7D9DD;
    padding: 20px 20px 30px 20px;
    border-radius: 10px 10px 0px 0px;
}
.card3{
    grid-column: 1/3;
    justify-items: start;
    align-items: center;
}
.card3 .card{
    width: 400px;
    margin: auto;
}
.mt40{
    margin-top: 40px;
}

/* ================ check box design ==================  */
.labelContainer {
    font-family: GilroyHome;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
}

    /* Hide the browser's default checkbox */
.labelContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    }

    /* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
}

    /* On mouse-over, add a grey background color */
.labelContainer:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.labelContainer input:checked ~ .checkmark {
    background-color: #00783F;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    }

    /* Show the checkmark when checked */
.labelContainer input:checked ~ .checkmark:after {
    display: block;
}

    /* Style the checkmark/indicator */
.labelContainer .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.list-item img{
    position: relative;
    top: 4px;
}
.list-item .labelContainer{
    padding: 0px 10px;
}
/* Media Query */

@media (min-width: 768px) and (max-width: 1024px) {
    .wrapper{
        max-width: 100%;
    }
    .card{
        margin-bottom: 2em;
    }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .wrapper{
        max-width: 100%;
    }
    .card{
        margin-bottom: 2em;
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    .wrapper{
        max-width: 100%;
    }
    .card{
        margin-bottom: 2em;
    }
    .card3 .card{
        width: 100%;
    }
    .wrapper-content {
        grid-template-columns: 100%;
        grid-gap: 0em;
    }
    .card3{
        grid-column: 1;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .wrapper{
        max-width: 100%;
    }
    .card{
        margin-bottom: 2em;
    }
    .card3 .card{
        width: 100%;
    }
    .wrapper-content {
        grid-template-columns: 100%;
        grid-gap: 0em;
    }
    .card3{
        grid-column: 1;
    }
}
.airdropper svg:first-child{
    width: 28.8px !important;
    height: 28.8px !important;
    margin-right: 3px;
}
.tradeIcon svg:first-child{
    margin-right: 6px;
}

.footerContactInner{
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0px;
}
.social-icons{
    display: flex;
    justify-content: space-between;
}
.social-title{
    font-family: GilroyHome;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding-bottom: 15px;
}
.social-icons ul{
    list-style: none;
}
.social-icons ul li{
    display: inline-block;
}
.social-icons ul li a{
    padding: 10px;
    background: rgba(90, 79, 51, 0.08);
    box-shadow: inset 11.9333px -11.9333px 11.9333px rgba(200, 156, 52, 0.08), inset -11.9333px 11.9333px 11.9333px rgba(200, 156, 52, 0.08);
    -webkit-backdrop-filter: blur(6.5px);
    backdrop-filter: blur(6.5px);
    border-radius: 8px;
    border: 1px solid #a1a1a1;
    display: inline-block;
    margin: 10px;
}
.social-icons ul li a img{
    width: 16px;
    height: 16px;
}
.subscription{
    padding-top: 15px;
    display: block;
    width: 100%;
    clear: both;
}
.subscription-title{
    font-family: GilroyHome;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #FAF3E8;
    padding-bottom: 20px;
    text-align: left;
}
.subscription-form form{ 
    position: relative;
}
.subscription form input{
    font-family: GilroyHome;
    width: 100%;
    padding: 15px;
    background: rgba(68, 54, 20, 0.08);
    box-shadow: inset 11.9333px -11.9333px 11.9333px rgba(200, 156, 52, 0.08), inset -11.9333px 11.9333px 11.9333px rgba(200, 156, 52, 0.08);
    -webkit-backdrop-filter: blur(6.5px);
            backdrop-filter: blur(6.5px);
    border-radius: 10px;
    border: 2px solid #F5F1EB;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #807373;
}
.subscription form button{
    position: absolute;
    right: 3px;
    top: 7px;
    background: transparent;
    border: none;
    cursor: pointer;
}
.alert-error{
    background-color: #c002022e;
    color: #c00202;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
}
.alert-success{
    background-color: #00784037;
    color: #00783F;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
}
@media (min-width: 768px) and (max-width: 1024px) {

}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .footerContactInner{
        display: grid;
        width: 100%;
        text-align: center;
        grid-template-columns: 100%;
    }
    .social-icons{
        display: block;
    }
    .contactLogo{
        margin-top: 40px;
        order: 2;
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    .footerContactInner{
        display: grid;
        width: 100%;
        text-align: center;
        grid-template-columns: 100%;
    }
    .social-icons{
        display: block;
    }
    .contactLogo{
        margin-top: 40px;
        order: 2;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .footerContactInner{
        display: grid;
        width: 100%;
        text-align: center;
        grid-template-columns: 100%;
    }
    .social-icons{
        display: block;
    }
    .contactLogo{
        margin-top: 40px;
        order: 2;
    }
}
.partners{
    font-family: GilroyHome;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1.5em;
    width: 1200px;
    margin: auto;
}
.text-center{
    text-align: center;
}
.partner-item{
    padding: 20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .partners{
        width: 100%;
    }
    .partner-item{
        padding: 10px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .partners{
        width: 100%;
    }
    .partner-item{
        padding: 20px;
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    .partners{
        width: 100%;
    }
    .partner-item{
        padding: 20px;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .partners{
        grid-template-columns: 33% 33% 33%;
        grid-gap: 0em;
        align-content: center;
        width: 100%;
    }
    .partner-item{
        padding: 20px;
    }
}
