.footerContactInner{
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0px;
}
.social-icons{
    display: flex;
    justify-content: space-between;
}
.social-title{
    font-family: GilroyHome;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding-bottom: 15px;
}
.social-icons ul{
    list-style: none;
}
.social-icons ul li{
    display: inline-block;
}
.social-icons ul li a{
    padding: 10px;
    background: rgba(90, 79, 51, 0.08);
    box-shadow: inset 11.9333px -11.9333px 11.9333px rgba(200, 156, 52, 0.08), inset -11.9333px 11.9333px 11.9333px rgba(200, 156, 52, 0.08);
    -webkit-backdrop-filter: blur(6.5px);
    backdrop-filter: blur(6.5px);
    border-radius: 8px;
    border: 1px solid #a1a1a1;
    display: inline-block;
    margin: 10px;
}
.social-icons ul li a img{
    width: 16px;
    height: 16px;
}
.subscription{
    padding-top: 15px;
    display: block;
    width: 100%;
    clear: both;
}
.subscription-title{
    font-family: GilroyHome;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #FAF3E8;
    padding-bottom: 20px;
    text-align: left;
}
.subscription-form form{ 
    position: relative;
}
.subscription form input{
    font-family: GilroyHome;
    width: 100%;
    padding: 15px;
    background: rgba(68, 54, 20, 0.08);
    box-shadow: inset 11.9333px -11.9333px 11.9333px rgba(200, 156, 52, 0.08), inset -11.9333px 11.9333px 11.9333px rgba(200, 156, 52, 0.08);
    backdrop-filter: blur(6.5px);
    border-radius: 10px;
    border: 2px solid #F5F1EB;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #807373;
}
.subscription form button{
    position: absolute;
    right: 3px;
    top: 7px;
    background: transparent;
    border: none;
    cursor: pointer;
}
.alert-error{
    background-color: #c002022e;
    color: #c00202;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
}
.alert-success{
    background-color: #00784037;
    color: #00783F;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
}
@media (min-width: 768px) and (max-width: 1024px) {

}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .footerContactInner{
        display: grid;
        width: 100%;
        text-align: center;
        grid-template-columns: 100%;
    }
    .social-icons{
        display: block;
    }
    .contactLogo{
        margin-top: 40px;
        order: 2;
    }
}
@media (min-width: 481px) and (max-width: 767px) {
    .footerContactInner{
        display: grid;
        width: 100%;
        text-align: center;
        grid-template-columns: 100%;
    }
    .social-icons{
        display: block;
    }
    .contactLogo{
        margin-top: 40px;
        order: 2;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .footerContactInner{
        display: grid;
        width: 100%;
        text-align: center;
        grid-template-columns: 100%;
    }
    .social-icons{
        display: block;
    }
    .contactLogo{
        margin-top: 40px;
        order: 2;
    }
}